/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// import "./static/css/nucleo-svg.css";
// import "./static/css/nucleo-icons.css";
// import "./static/scss/argon-design-system.scss";
// import './static/css/argon-design-system.css';

export const onServiceWorkerUpdateReady = () => {
    window.location.reload(true);
};